import React from "react";
import { Button, Col, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";

import useFicha from "./hooks/useFicha";

export default function Ficha() {
  const location = useLocation();
  let history = useHistory();

  const { items, productReadytoPay } = useFicha();

  const product = location.state
    ? location.state.product
    : items.length > 0
    ? items[0]
    : history.push("/planes");

  console.log(product, "product");

  window.scrollTo(0, 0);

  return (
    <>
      <Row className="m-0 mt-5 mb-4 p-0 justify-content-center">
        <Col
          className="text-left p-2 pl-4"
          lg={10}
          style={{
            backgroundColor: "darkgray",
            fontSize: "1.7rem",
            borderRadius: "5px",
            color: "black",
          }}
        >
          Planes de Ahorro
        </Col>
      </Row>

      <Row className="m-0 p-0 my-5 justify-content-center">
        <Col xs={12} md={10}>
          <Row>
            <Col xs={12} md={6}>
              <Row className="m-0 p-0">
                <Col
                  xs={12}
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    borderRadius: "2px",
                  }}
                >
                  Detalle
                </Col>
                <Col
                  xs={12}
                  style={{
                    backgroundColor: "#efdf00",
                    color: "white",
                    borderRadius: "2px",
                    height: "8px",
                  }}
                />
                <Col
                  xs={12}
                  style={{
                    backgroundColor: "#f5f5f5",
                    color: "white",
                    borderRadius: "2px",
                    height: "8px",
                    borderBottom: "1px solid lightgrey",
                  }}
                />
                <Col
                  xs={6}
                  className="py-2"
                  style={{
                    backgroundColor: "#f5f5f5",
                    color: "black",
                    fontWeight: "bold",
                    borderBottom: "1px solid lightgrey",
                  }}
                >
                  Total:{" "}
                  <div style={{ fontSize: 12 }}>
                    Precio público sin <br />
                    impuestos nacionales:{" "}
                    {Intl.NumberFormat("es-AR", {
                      style: "currency",
                      currency: "ARS",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(product?.precio_plan / 1.21)}
                  </div>
                </Col>
                <Col
                  xs={6}
                  className="py-2"
                  style={{
                    backgroundColor: "#f5f5f5",
                    color: "black",
                    fontWeight: "bold",
                    borderBottom: "1px solid lightgrey",
                  }}
                >
                  {Intl.NumberFormat("es-AR", {
                    style: "currency",
                    currency: "ARS",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(product?.precio_plan)}
                </Col>
                <Col
                  xs={6}
                  className="py-2"
                  style={{
                    backgroundColor: "#f5f5f5",
                    color: "black",
                    fontWeight: "bold",
                    borderBottom: "1px solid lightgrey",
                  }}
                />

                <Col
                  xs={6}
                  className="py-2"
                  style={{
                    backgroundColor: "#f5f5f5",
                    color: "black",
                    fontWeight: "bold",
                    borderBottom: "1px solid lightgrey",
                  }}
                >
                  <Col xs={3} className="m-0 p-0 text-center">
                    <Link
                      to={{
                        pathname: "/tarjetas",
                        state: {
                          product: productReadytoPay && productReadytoPay,
                        },
                      }}
                      style={{ color: "black" }}
                    >
                      <Button
                        size="sm"
                        variant="outline-light"
                        className="styled-nav"
                        style={{
                          border: "1px solid black",
                          fontWeight: "bold",
                          fontFamily: "Renault Bold",
                          borderRadius: "0px",
                          color: "white",
                          backgroundColor: "black",
                        }}
                      >
                        contratar
                      </Button>
                    </Link>
                  </Col>
                </Col>

                {product?.cuotas.map((cuota, i) => {
                  return (
                    <>
                      <Col
                        xs={6}
                        className="py-2"
                        style={{
                          backgroundColor: "#f5f5f5",
                          color: "black",
                          fontWeight: "bold",
                          borderBottom: "1px solid lightgrey",
                        }}
                      >
                        {cuota.cuota}
                        <div style={{ fontSize: 12 }}>
                          Precio público sin <br /> impuestos nacionales:{" "}
                          {Intl.NumberFormat("es-AR", {
                            style: "currency",
                            currency: "ARS",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(cuota.valor / 1.21)}
                        </div>
                      </Col>
                      <Col
                        xs={6}
                        className="py-2"
                        style={{
                          backgroundColor: "#f5f5f5",
                          color: "black",
                          fontWeight: "bold",
                          borderBottom: "1px solid lightgrey",
                        }}
                      >
                        {Intl.NumberFormat("es-AR", {
                          style: "currency",
                          currency: "ARS",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(cuota.valor)}
                      </Col>
                    </>
                  );
                })}
              </Row>
              <span style={{ fontSize: "12px" }}>
                * Valores sujetos a revisión.
              </span>
            </Col>
            <Col xs={12} md={6}>
              <Row className="m-0 p-0 mb-3 justify-content-center">
                <Col xs={12} md={6}>
                  <Image
                    src={`https://store.clama.com.ar/img/${product?.foto0}`}
                    width="100%"
                  ></Image>
                </Col>
                <Col
                  xs={12}
                  className="text-center"
                  style={{ fontWeight: "bold" }}
                >
                  {product?.version}
                  <br />
                  Precio{" "}
                  {Intl.NumberFormat("es-AR", {
                    style: "currency",
                    currency: "ARS",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }).format(product?.precio_vehiculo)}
                  <br />
                  {product?.tipo_plan}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="m-0 p-0 mb-5 justify-content-center">
        <Col className="mx-3" xs={10} style={{ fontSize: "12px" }}>
          MUY IMPORTANTE: LAS CUOTAS NO SON FIJA Y SE AJUSTAN MENSUALMENTE Y
          CONJUNTAMENTE CON LA EVOLUCIÓN DEL PRECIO DEL VEHÍCULO, CONFORME SE
          ESTABLECE EXPRESAMENTE EN EL CONTRATO. Los valores de cuotas INCLUYEN
          estimativo seguro de vida, que se calculara al momento de la emisión
          de cuota e incluido en la misma, y sera calculado de la siguiente
          manera: Planes 100% - 0,073% sobre el saldo de cuotas puras a vencer
          Planes 75/25% - 0,073% sobre el saldo de cuotas puras a vencer mas la
          cuota extraordinaria Los contratos tributan impuesto de sellos según
          disposición de cada jurisdicción, y ESTAN INCLUDOS a valor estimado
          para su referencia. *INTEGRACIÓN MÍNIMA planes 84 cuotas 24 CUOTAS -
          planes 120 cuotas 12 CUOTAS *TODOS LOS PRECIOS SON A TITULO
          INFORMATIVO E INCLUYEN ESTIMADO DE SELLADO Y DE SEGURO DE VIDA, POR LO
          QUE PUEDEN SUFRIR VARIACIONES POR PARTE DE RENAULT PLAN ROMBO.
        </Col>
      </Row>
    </>
  );
}
