import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
export const repContext = React.createContext();

export default function RepuestosContext({ children }) {
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const paramId = queryParameters.get("id");

  const QUERYLIMIT = 21;
  const PAGESONSCREEN = 4;
  const POSTPERPAGE = 20;

  const [currentPage, setCurrentPage] = useState(1);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(QUERYLIMIT);
  const [totalItems, setTotalItems] = useState(0);
  const totalPages = parseInt(totalItems / POSTPERPAGE + 1);
  const [repuestos, setRepuestos] = useState([]);
  const [seleccion, setSeleccion] = useState("Todos");
  const [spinner, setSpinner] = useState(false);

  const [search, setSearch] = useState("");
  const [emptySearch, setEmptySearch] = useState(false);

  useEffect(() => {
    setSpinner(true);
    let url;

    if (paramId !== null) {
      url = `https://store.clama.com.ar/clamaapi/repuestos/share/${paramId}`;
    } else if (seleccion === "combos" && paramId === null) {
      url = `https://store.clama.com.ar/clamaapi/repuestos/combos/${min}/${max}`;
    } else if (seleccion !== "combos" && paramId === null) {
      url = `https://store.clama.com.ar/clamaapi/repuestos/modelos/${seleccion}/${min}/${max}`;
    }

    fetch(url)
      .then((data) => {
        return data.json();
      })
      .then((response) => {
        setRepuestos(response.data);
        !paramId && setTotalItems(response.total[0].total);
        setSpinner(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, seleccion, emptySearch]);

  useEffect(() => {
    setCurrentPage(1);
    setMin(0);
    setMax(21);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seleccion]);

  const handleChangeFilter = (event) => {
    setSearch(event.target.value);
  };

  const handlePagination = (page) => {
    console.log("🚀 ~ page:", page);
    setCurrentPage(page);
    setMin((page - 1) * QUERYLIMIT);
    setMax(page * QUERYLIMIT);
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      setMin(min + QUERYLIMIT);
      setMax(max + QUERYLIMIT);
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setMin(min - QUERYLIMIT);
      setMax(max - QUERYLIMIT);
    }
  };

  const handleSearch = () => {
    if (search === "") {
      setEmptySearch(!emptySearch);
    }

    setSpinner(true);
    let url = `https://store.clama.com.ar/clamaapi/repuestos/search/${search}/modelo/${seleccion}/${min}/${max}`;

    fetch(url)
      .then((data) => {
        return data.json();
      })
      .then((response) => {
        setRepuestos(response.data);
        setTotalItems(response.total[0].total);
        setSpinner(false);
      });
  };

  useEffect(() => {
    setSearch("");
  }, [seleccion]);
  return (
    <repContext.Provider
      value={{
        paramId,
        emptySearch,
        setEmptySearch,
        repuestos,
        POSTPERPAGE,
        setSeleccion,
        seleccion,
        handleChangeFilter,
        spinner,
        handleSearch,
        search,
        handlePagination,
        currentPage,
        totalPages,
        PAGESONSCREEN,
        totalItems,
        handlePrev,
        handleNext,
      }}
    >
      {children}
    </repContext.Provider>
  );
}
