import { useState, useEffect } from "react";

export default function usePlanes() {
  const [modelos, setModelos] = useState([]);
  const [spinner, setSpinner] = useState(false);

  useEffect(() => {
    setSpinner(true);
    var url = "https://store.clama.com.ar/clamaapi/planes";

    const fetchData = async () => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setModelos(data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        const catchError = async () => {
          try {
            var url = "https://store.clama.com.ar/clamaapi/planes";
            const response = await fetch(url);
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            const modelos = await response.json();
            console.log(process.env.REACT_APP_BREVO_API_KEY);
            console.log("Data fetched:", modelos);
          } catch (error) {
            console.error("Error fetching data:", error);
            try {
              const response = await fetch(
                "https://api.brevo.com/v3/smtp/email",
                {
                  method: "POST",
                  headers: {
                    "api-key": process.env.REACT_APP_BREVO_API_KEY,
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    sender: { email: "clamadevelopers@gmail.com" },
                    to: [{ email: "clamadevelopers@gmail.com" }],
                    subject: "🚨 Fallo en PLANES CLAMA",
                    htmlContent: `<p>El backend no está respondiendo.</p>`,
                  }),
                }
              );
              if (response.ok) {
                console.log("Correo de error enviado!");
              } else {
                console.error(
                  "Error enviando el email:",
                  await response.json()
                );
              }
            } catch (err) {
              console.error("Error en la petición a Brevo:", err);
            }
          }
        };
        catchError();
      } finally {
        setSpinner(false);
      }
    };

    fetchData();
  }, []);

  const handleButtonClick = (product) => {
    localStorage.setItem("items", JSON.stringify(product));
    /* localStorage.clear(); */
  };

  return {
    modelos,
    spinner,
    handleButtonClick,
  };
}
