import React from "react";
import { Button, Carousel, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import carousel_1 from "../../../assets/images/carousel/1.jpg";
import carousel_2 from "../../../assets/images/carousel/2.jpg";
import carousel_3 from "../../../assets/images/carousel/3.jpg";
import carousel_4 from "../../../assets/images/carousel/4.jpg";
/* import carousel_5 from "../../../assets/images/carousel/5.jpg";
import carousel_6 from "../../../assets/images/carousel/6.jpg"; */

import { DateTime } from "luxon";

export default function Index() {
  /*  const [videoLoaded, setVideoLoaded] = useState(true);
  const [videoEnded, setVideoEnded] = useState(false); */

  const videoData = {
    alaskanWinter: {
      video: "https://store.clama.com.ar/v2/videos/alaskanWinter.mp4",
      title: "RENAULT WINTER",
      action: "Descubrí Alaskan",
      description:
        "Descubrí Alaskan en la nieve. Visitá el stand de Renault en Cerro Bayo y disfrutá de los beneficios",
      imagen: carousel_1,
    },
    alaskan: {
      video: "https://store.clama.com.ar/v2/videos/alaskan.mp4",
      title: "RENAULT ALASKAN",
      action: "Descubrí Alaskan",
      description:
        "Hicimos una pick-up hecha con la fuerza de los que hacen. Potente y versátil para todo lo que tenés que hacer.",
      imagen: "https://store.clama.com.ar/v2/img/alaskan/banner.jpg",
    },
    stepway: {
      video: "https://store.clama.com.ar/v2/videos/stepway.mp4",
      title: "RENAULT STEPWAY",
      action: "Descubrí Stepway",
      description:
        "un vehículo con actitud para disfrutar la aventura de vivir en la ciudad.",
      imagen: "https://store.clama.com.ar/v2/img/stepway/banner.jpg",
    },
    kardian: {
      video: "",
      title: "RENAULT KARDIAN",
      action: "el cambio que lo cambia todo",
      description:
        "Disfrutá de un espacio acogedor diseñado tanto para el conductor como para los pasajeros, con materiales de primera calidad y acabados de alta gama.",
      imagen: "https://store.clama.com.ar/v2/img/kardian/banner.jpg",
    },
    niagara: {
      video: "",
      title: "RENAULT NIAGARA",
      action: "Símbolo de la ofensiva internacional",
      description:
        "RENAULT NIAGARA CONCEPT presenta líneas generosas y envolventes, así como detalles esculpidos y acabados en un vehículo que es a la vez robusto y contemporáneo.",
      imagen: "https://store.clama.com.ar/v2/img/niagara/banner.jpg",
    },
    megane: {
      video: "",
      title: "RENAULT MEGANE E-TECH 100% ELÉCTRICO",
      action: "de caballos de fuerza a energía eléctrica",
      description:
        "Renault Megane E-Tech 100% eléctrico cuenta con un innovador diseño crossover deportivo, pensado para los conductores más exigentes",
      imagen: "https://store.clama.com.ar/v2/img/megane/banner.jpg",
    },
    kwid: {
      video: "",
      title: "RENAULT KWID E-TECH 100% ELÉCTRICO",
      action: "la revolución eléctrica está en las calles.",
      description:
        "Descubrí las ventajas de conducir un vehículo eléctrico experimentando una conducción silenciosa y respetuosa con el medio ambiente, ideal para el tráfico urbano de ciudades.",
      imagen: "https://store.clama.com.ar/v2/img/kwid/banner.jpg",
    },
    "nuevo kwid": {
      video: "",
      title: "NUEVO RENAULT KWID",
      action: "el SUV de los compactos",
      description:
        "Nuevo Renault Kwid llegó totalmente renovado y robusto. Esta versión llega con su parte delantera completamente nueva.",
      imagen:
        "https://store.clama.com.ar/v2/img/nuevo_kwid/banner_principal.jpg",
    },
  };

  const today = DateTime.now().weekday;

  const dataToRender = () => {
    if (today === 1 || today === 3) {
      return videoData["nuevo kwid"];
    } else if (today === 2 || today === 4) {
      return videoData.kardian;
    } else if (today === 5) {
      return videoData.megane;
    } else if (today === 6) {
      return videoData.kwid;
    } else {
      return videoData.kardian;
    }
  };

  const handleVideo = () => {
    return (
      <>
        {/* {!videoEnded && (
          <video
            controls={false}
            autoPlay
            muted
            preload={"auto"}
            src={dataToRender().video}
            type="video/mp4"
            className="d-block w-100 carouselItemHeight"
            width={"100%"}
            onLoadedData={() => setVideoLoaded(false)}
            onEnded={() => setVideoEnded(true)}
          >
            Tu navegador no soporta videos.
          </video>
        )}

        {videoLoaded && (
          <>
            <Row className="text-center">
              <Col
                className="ml-3"
                style={{ position: "absolute", top: "40%" }}
              >
                <Spinner animation="border" role="status" />
                <br />
                cargando video
              </Col>
            </Row>
          </>
        )} */}
        {/* {videoEnded && ( */}
        <img
          className="d-block w-100 carouselItemHeight fade-in"
          src={dataToRender().imagen}
          alt="First slide"
        />
        {/* )} */}
      </>
    );
  };

  const handleButton = () => {
    if (today === 1 || today === 3) {
      return (
        <Link
          to={{
            pathname: "/modelos",
            state: { modelo: "nuevo kwid" },
          }}
        >
          <Button className="itemCarouselButtonStyle">ver más</Button>
        </Link>
      );
    } else if (today === 2 || today === 4) {
      return (
        <Link
          to={{
            pathname: "/modelos",
            state: { modelo: "megane" },
          }}
        >
          <Button className="itemCarouselButtonStyle">ver más</Button>
        </Link>
      );
    } else if (today === 5 || today === 6) {
      return (
        <Link
          to={{
            pathname: "/modelos",
            state: { modelo: "megane" },
          }}
        >
          <Button className="itemCarouselButtonStyle">ver más</Button>
        </Link>
      );
    } else {
      return (
        <Link
          to={{
            pathname: "/modelos",
            state: { modelo: "kardian" },
          }}
        >
          <Button className="itemCarouselButtonStyle">ver más</Button>
        </Link>
      );
    }
  };

  return (
    <Row className="m-0 p-0 shadow" id="corouselPosition">
      <Col className="p-0">
        <Carousel
          fade
          className="carouselHeight"
          interval={6000} //cambiar a 12000
          controls={false}
        >
          <Carousel.Item>
            {handleVideo()}
            <div className="linearGradientBackground" />

            <Carousel.Caption className="carouselCaption text-left">
              <p className="m-0 p-0">{dataToRender().title}</p>
              <p
                className="m-0 p-0"
                style={{ fontSize: "2vh", paddingLeft: "0.3%" }}
              >
                {dataToRender().action}
              </p>
              {handleButton()}
            </Carousel.Caption>

            <Row className="m-0 p-0 carouselCaptionFooter">
              <Col className="carouselCaptionDescription text-center">
                {dataToRender().description}
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 carouselItemHeight"
              src={carousel_2}
              alt="First slide"
            />
            <div className="linearGradientBackground" />
            <Carousel.Caption className="carouselCaption text-left">
              <p className="m-0 p-0">accesorios para tu Renault</p>
              <p
                className="m-0 p-0"
                style={{ fontSize: "2vh", paddingLeft: "0.3%" }}
              >
                comprá lo que tu Renault necesite
              </p>
              <Link to="/accesorios">
                <Button className="itemCarouselButtonStyle">
                  ver catálogo
                </Button>
              </Link>
            </Carousel.Caption>
            <Row className="m-0 p-0 carouselCaptionFooter">
              <Col className="carouselCaptionDescription text-center">
                Querés agregar más personalidad a tu Renault? Tenemos todo lo
                que necesitás para crear tu propio Renault.
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 carouselItemHeight"
              src={carousel_3}
              alt="First slide"
            />
            <div className="linearGradientBackground" />
            <Carousel.Caption className="carouselCaption text-left">
              <p className="m-0 p-0">Plan Rombo</p>
              <p
                className="m-0 p-0"
                style={{ fontSize: "2vh", paddingLeft: "0.3%" }}
              >
                ahora 100% online
              </p>
              <Link to="/planes">
                <Button className="itemCarouselButtonStyle">ver planes</Button>
              </Link>
            </Carousel.Caption>
            <Row className="m-0 p-0 carouselCaptionFooter">
              <Col className="carouselCaptionDescription text-center">
                encontrá tu 0km de forma fácil, rápida y segura
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 carouselItemHeight"
              src={carousel_4}
              alt="First slide"
            />
            <div className="linearGradientBackground" />
            <Carousel.Caption className="carouselCaption text-left">
              <p className="m-0 p-0">MY RENAULT</p>
              <p
                className="m-0 p-0"
                style={{ fontSize: "2vh", paddingLeft: "0.3%" }}
              >
                donde quiera que vayas, disfrutá de los beneficios
              </p>
              <a
                href="https://myrenault.com.ar/login"
                target="_blank"
                rel="noreferrer"
              >
                <Button className="itemCarouselButtonStyle">registrate</Button>
              </a>
            </Carousel.Caption>
            <Row className="m-0 p-0 carouselCaptionFooter">
              <Col className="carouselCaptionDescription text-center">
                Accediendo podes ser parte de propuestas exclusivas, descuentos
                preferenciales, accesos a eventos, sorteos y muchos más.
              </Col>
            </Row>
          </Carousel.Item>
        </Carousel>
      </Col>
    </Row>
  );
}
