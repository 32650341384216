import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent,
  SidebarFooter,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";

import { Row, Col, Image } from "react-bootstrap/";

import { uiContext } from "../../contexts/UiContextProvider";

import renaultMini from "../../assets/images/sideBar/renault.png";
import gri from "../../assets/images/sideBar/gri.png";

//Breakpoints
import { HideAt } from "react-with-breakpoints";
import { MARCAS } from "../../constants/constants";
import { NavLink } from "react-router-dom";

export default function SideBar() {
  const uiManager = useContext(uiContext);

  let height = window.innerHeight;
  let footerHeight = (32.8 * height) / 100;

  const handleButtonClick = (product) => {
    localStorage.setItem("items", JSON.stringify(product));
  };

  return (
    <ProSidebar
      collapsed={uiManager.sideBar.collapsed}
      breakPoint={"md"}
      toggled={uiManager.sideBar.toggled}
      onToggle={uiManager.sideBar.hide}
      rtl={true}
    >
      <SidebarHeader
        style={{
          /* overflow: "hidden", */
          color: "black",
          borderLeft: "1px solid lightGrey",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Row className="m-0 p-0">
          <Col
            style={{ paddingTop: "20px", color: "black", height: "60px" }}
            onClick={uiManager.sideBar.hide}
          >
            <FontAwesomeIcon icon="times-circle" className="shake" />
          </Col>
        </Row>
      </SidebarHeader>

      <SidebarContent
        style={{
          borderLeft: "1px solid lightGrey",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Menu>
          <MenuItem>
            <a
              href="https://store.clama.com.ar/vtf/"
              onClick={uiManager.sideBar.hide}
              style={{ color: "black" }}
            >
              VEHICULOS
            </a>
          </MenuItem>
          <MenuItem>
            <NavLink
              to={{
                pathname: "/usados/catalogo",
                state: { product: { marca: MARCAS.OTRAS } },
              }}
              onClick={() => {
                handleButtonClick({ marca: MARCAS.OTRAS });
                uiManager.sideBar.hide();
              }}
              style={{ color: "black" }}
            >
              USADOS
            </NavLink>
          </MenuItem>
          {/*     <MenuItem>
            <a
              href="https://store.clama.com.ar/cotizador/"
              onClick={uiManager.sideBar.hide}
              style={{ color: "black" }}
            >
              COTIZÁ TU USADO
            </a>
          </MenuItem>
          <MenuItem>
            <a
              href="https://store.clama.com.ar/rci/"
              onClick={uiManager.sideBar.hide}
              style={{ color: "black" }}
            >
              FINANCIACIÓN ONLINE
            </a>
          </MenuItem> */}
          <MenuItem>
            <NavLink
              to="/planes"
              onClick={uiManager.sideBar.hide}
              style={{ color: "black" }}
            >
              PLAN ROMBO
            </NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink
              to="/servicios"
              onClick={uiManager.sideBar.hide}
              style={{ color: "black" }}
            >
              SERVICIOS
            </NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink
              to="/accesorios"
              onClick={uiManager.sideBar.hide}
              style={{ color: "black" }}
            >
              ACCESORIOS
            </NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink
              to="/repuestos"
              onClick={uiManager.sideBar.hide}
              style={{ color: "black" }}
            >
              REPUESTOS
            </NavLink>
          </MenuItem>
          <hr></hr>

          <MenuItem icon={<Image src={renaultMini} />}>
            <a
              href="https://myrenault.com.ar/pre_landing"
              target="_blank"
              rel="noreferrer"
              style={{ color: "black", fontFamily: "Renault Bold" }}
            >
              MY RENAULT
            </a>
          </MenuItem>
          <MenuItem icon={<Image src={renaultMini} />}>
            <a
              href="https://www.renault.com.ar/servicios.html"
              target="_blank"
              rel="noreferrer"
              style={{
                color: "black",
                fontFamily: "Renault Bold",
              }}
            >
              car
              <span
                style={{
                  display: "inline-block",
                  transform: "rotate(-30deg)",
                  color: "#ffd600",
                }}
              >
                e
              </span>
              &nbsp; service
            </a>
          </MenuItem>
          <MenuItem icon={<Image src={gri} />}>
            <a
              href="https://www.clama.com.ar/contactenos/nuestra_empresa"
              target="_blank"
              rel="noreferrer"
              style={{
                color: "black",
                fontFamily: "Renault Bold",
              }}
            >
              sostenibilidad
            </a>
          </MenuItem>
        </Menu>
        <HideAt breakpoint="mediumAndAbove"></HideAt>
      </SidebarContent>
      <SidebarFooter
        className="dynamicFooter"
        style={{ backgroundColor: "#f5f5f5", height: footerHeight }}
      ></SidebarFooter>
    </ProSidebar>
  );
}
